import { Redirect, RouteProps as ReactDomRouteProps } from "react-router";
import { SideBar } from "../../components/SideBar";
import { useAuth } from "../../hooks/auth";
import { useHeader } from "../../hooks/headerHook";
import { Route } from "../Route";
import { Container, ContentArea, Title } from "./styles";

interface RouteProps extends ReactDomRouteProps {
  component: any;
  path: string;
  exact: boolean;
}

export const PrivateRoute: React.FC<RouteProps> = ({ ...rest }) => {
  const { getPayload } = useAuth();
  const { header, setHeader } = useHeader();
  if (!getPayload()._id) {
    return (
      <Redirect
        to={{
          pathname: "/login",
        }}
      />
    );
  }

  if (
    document.location.pathname !== "/discador" &&
    getPayload().userType.name === "Atendente"
  ) {
    return (
      <Redirect
        to={{
          pathname: "/discador",
        }}
      />
    );
  }

  if (
    document.location.pathname !== "/sistema" &&
    getPayload().userType.name === "Auditor"
  ) {
    return (
      <Redirect
        to={{
          pathname: "/auditoria",
        }}
      />
    );
  }

  return (
    <Container>
      {getPayload().userType.name === "Admin" && (
        <SideBar setMenuTitle={setHeader} />
      )}

      <ContentArea>
        <Title>{header}</Title>
        <Route {...rest} />
      </ContentArea>
    </Container>
  );
};
