import { combineReducers } from "redux";
import { loaderReduce } from "./loader.reducer";
import { reloadComponentsReduce } from "./reloadComponents.reducer";
import { stateField } from "./stateField.reducers";

export const Reducers = combineReducers({
  loaderState: loaderReduce,
  reloadComponents: reloadComponentsReduce,
  stateFields: stateField
});

export type RootState = ReturnType<typeof Reducers>;
