import styled from "styled-components";

export const Wrapper = styled.tr`
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 2px;
`;

export const Container = styled.td<{ width: string }>`
  width: ${props => props.width};
  max-width: ${props => props.width};
  padding: 0 2px;
`;

export const ScheduleOptionButton = styled.button<{ isCancel?: boolean }>`
  width: 100%;
  color: ${props => props.isCancel ? "#FFF" :"#1F0533"};
  background: ${props => props.isCancel ? "#F80000" :"#00FFE6"};
  border: none;
  padding: 4px 0;
  border-radius: 4px;
  cursor: pointer;
`;