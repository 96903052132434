import styled from "styled-components";

export const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
  box-sizing:border-box;
  padding: 32px;
  overflow-y: auto;

  & h1 {
    font-size: 40px;
    font-weight: bold;
  }
`;

export const AuditHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AuditReload = styled.button`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 16px;
  border-radius: 5px;
  background: rgba(196,196,196, 0.3);
  border: 0;
  cursor: pointer;
  
  &:hover {
    background: rgb(161, 161, 161, 0.3);
  }
`;

export const AuditsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin-top: 48px;
`;

export const AuditsCard = styled.div`
  border: 1px solid white;
  padding: 16px;
  width: 48%;
  border-radius: 8px;
  box-sizing: border-box;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & h4 {
    font-size: 22px;
    font-weight: bold;
  }

  & h5 {
    font-size: 14px;
    margin-top: 2px;
  }
`;

export const AuditsInfos = styled.div`
  margin-top: 16px;
`;

export const ButtonsContainer = styled.div`
  margin-top: 16px;
`;

export const CallButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  background: rgba(196,196,196, 0.3);
  border: none;
  padding: 6px 0;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background: rgb(161, 161, 161, 0.3);
  }
`;

export const SelectContainer = styled.div`
  margin: auto;
  margin-top: 16px;
  width: 50%;
`;
