import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 24px;
  width: 100%;
  border: 1px solid white;
  border-radius: 8px;
  padding: 8px 16px;


  
  & h5 {
    font-weight: bold;
  }
`;

export const AddContainer = styled.div`
  display: flex;
  align-items: center;

  & button {
    color: #ffffff;
    height: 32px;
    margin-left: 16px;
    background: rgba(0, 0, 0, 0.4);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.6);
    }
  }
`;

export const InputContainer = styled.div`
  margin-left: 16px;

  & input {
    background: none;
    border: 1px solid white;
    border-radius: 5px;
    height: 28px;
    color: white;
    padding: 0 8px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }
`;

export const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;

  & p {
    margin-right: 4px;
    font-size: 14px;
  }

  & svg {
    cursor: pointer;
  }
`;