import React, { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { schedulingCallTab } from '../helper';
import { CenterContainer, ConfirmButton, ErrorMessage, TimeInputContainer, Wrapper } from './styles';
import { setModalOpened } from 'store/modal/actions';
import dayjs from 'dayjs';

interface IProps {
  observations: string
  tabbingStatus: any;
}

const SchedulingModal: React.FC<IProps> = ({observations, tabbingStatus}: IProps) => {  
  const [calendarValue, setCalendarValue] = useState(new Date());
  const [hourValue, setHourValue] = useState("");
  const [minValue, setMinValue] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const hourInput = useRef<HTMLInputElement>(null);
  const minInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (hourValue.length === 2) {
      minInput.current?.focus();
    }
  }, [hourValue]);

  const minKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Backspace" && minValue.length === 0) {
      hourInput.current?.focus();
    }
  }

  const callSchedulingMethod = async () => {
    let month: string | number = calendarValue.getMonth() + 1;

    if (month < 10) {
      month = `0${month}`;
    }

    const selectedDate = `${calendarValue.getFullYear()}-${month}-${calendarValue.getDate()}`;
    const selectedTime = `${hourValue}:${minValue}`;

    const dateNow = dayjs();
    const dateChoosed = dayjs(`${selectedDate}T${selectedTime}`);

    if (!dateChoosed.isValid()) {
      setErrorMessage('Hora inválida. Por favor informe um horário válido.');
      return;
    }

    if (hourValue === "" || minValue === "") {
      setErrorMessage('Hora inválida. Por favor informe um horário válido.');
      return;
    }

    if (Number(hourValue) > 24 || Number(minValue) > 60) {
      setErrorMessage('Hora inválida. Por favor informe um horário válido.');
      return;
    }

    if (dateChoosed.isBefore(dateNow)) {
      setErrorMessage('Este horário já passou. Por favor agende um horário válido.');
      return;
    }

    setModalOpened(false);

    await schedulingCallTab({
        hourAg: selectedTime,
        dateAg: selectedDate,
      }, 
      observations,
      tabbingStatus
    );
  }

  return (
    <Wrapper>
      <h1>Agendar novo</h1>      
      <CenterContainer>
        <Calendar onChange={setCalendarValue} value={calendarValue} />
      </CenterContainer>
      <h3>Horário</h3>
      <CenterContainer>
        <TimeInputContainer>
          <input 
            type="text" 
            maxLength={2} 
            ref={hourInput} 
            onChange={(event) => setHourValue(event.target.value)}
          />
          <p>:</p>
          <input 
            type="text"  
            maxLength={2} 
            ref={minInput} 
            onKeyDown={(event) => minKeyDown(event)}
            onChange={(event) => setMinValue(event.target.value)}
          />
        </TimeInputContainer>
      </CenterContainer>
      {errorMessage !== "" && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <ConfirmButton onClick={() => callSchedulingMethod()}>
        Agendar
      </ConfirmButton>
    </Wrapper>
  );
}

export default SchedulingModal;