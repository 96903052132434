import { request } from "services/request";

const getAudits = async (): Promise<any> => {
  return await request({
    method: "GET",
    path: `audits`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const newAuditCall = async (callId: string): Promise<any> => {
  return await request({
    method: "POST",
    path: `new-call/${callId}`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const saveAuditStatus = async (auditId: string, status: string): Promise<any> => {
  return await request({
    method: "PUT",
    path: `save-audit-status/${auditId}`,
    data: {
      status,
    },
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

const getAuditWithoutStatus = async (auditId: string): Promise<any> => {
  return await request({
    method: "GET",
    path: `get-audit-without-status/${auditId}`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
  });
}

export {
  getAudits,
  newAuditCall,
  saveAuditStatus,
  getAuditWithoutStatus,
}
