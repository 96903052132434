import { ICall } from "interfaces/ICall";
import ISipCallData from "interfaces/ISipCallData";
import { Store } from "pullstate";
import { UserAgent } from "sip.js";

interface IProps {
  connected: boolean;
  inCallData?: ICall;
  sipJsUa?: UserAgent;
  inCall: boolean;
  callSession: any,
  micMuted: boolean,
  ramal: string,
  inPause: boolean,
  approached: boolean,
  sipCallData?: ISipCallData,
  type: 'FILA' | 'REDISCAGEM',
  callCreated?: ICall | { _id: string };
  actualAudit?: any;
}

export const CallStore = new Store<IProps>({
   connected: false,
   inCallData: undefined,
   sipJsUa: undefined,
   inCall: false,
   callSession: {},
   micMuted: false,
   ramal: "",
   inPause: false,
   approached: false,
   sipCallData: undefined,
   type: 'FILA',
   callCreated: undefined,
   actualAudit: undefined,
});
