import { request } from "services/request";
import sweetAlert from "utils/sweetAlert";

export const handleRetry = async (
  retry: boolean,
  callWithoutTabbing: any,
  setInCall: Function,
  setInCallData: (p: any) => void,
  setTabbingStatus: (value: any) => void,
  setCallWithoutTabbing: Function,
  setApproached: React.Dispatch<React.SetStateAction<boolean>>,
  setSipData: React.Dispatch<React.SetStateAction<undefined>>,
  setSelectedPreTabbingStatus: React.Dispatch<any>,
  inPause: boolean,
  ) => {
  if (!retry) {
    try {
      if (!inPause) {
        await request({
          method: "POST",
          path: `awaiting-call`,
          data: {},
          showSuccessMessage: false,
          showErrorMessage: false,
        });
      }
    } catch {
      window.location.reload();
    }

    if (callWithoutTabbing) {
      window.location.reload();
      return;
    }

    setInCall(false);
    setInCallData(undefined);
    setTabbingStatus(null);
    setCallWithoutTabbing(false);
    setSipData(undefined);
    setApproached(false);

    if (!inPause) {
      await request({
        method: "POST",
        path: `remove-pause`,
        showSuccessMessage: false,
        showLoading: false,
        data: {},
      });
    }
  } else {
    setTabbingStatus(null);
    setInCallData(undefined);
    setCallWithoutTabbing(false);
    setInCall(false);
    setInCall(true);
    setApproached(false);
    setSelectedPreTabbingStatus(null);
  }
}

export const handleSchedulingCall = async (callID: string) => {
  const scheduleResponse = await request({
    method: "POST",
    path: `${callID}/save-tabbing-status`,
    data: {
      tabbingStatus: {
        type: 'rediscar',
      },
      ag: false,
      retry: true,
    },
    showSuccessMessage: false,
  });

  return scheduleResponse;
}

export const postponeScheduling = async (
  callId: string,
  tabbingStatus: any
) => {
  let res = await request({
    method: "POST",
    path: `postpone-call`,
    data: {
      callId
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showLoading: false,
  });

  return res;
}

export const handleInPauseScheduling = async (setInPause: (value: React.SetStateAction<boolean>) => void) => {
  const resPause = await request({
    method: "POST",
    path: `pause-queue`,
    showSuccessMessage: false,
    showLoading: false,
  });

  if (!resPause.error) {
    setInPause(true);
  }
}

export const approachCall = async (
  setApproached: React.Dispatch<React.SetStateAction<boolean>>,
  setInCallData: (p: any) => void
  ) => {
  try {
    const call = await request({
      method: "GET",
      path: `approach`,
      showSuccessMessage: false,
      showLoading: false,
      showErrorMessage: false,
    });

    setApproached(true);
    setInCallData(call);
  } catch {
    // sweetAlert.error("Ocorreu um erro ao abordar. Contate o suporte!");
  }
}

export const getPreTabbingStatus = async (setPreTabbingStatus: React.Dispatch<any>) => {
  try {
    const preTabbingStatus = await request({
      method: "GET",
      path: `get-pre-tabbing-status`,
      showSuccessMessage: false,
      showLoading: false,
      showErrorMessage: false,
    });
    setPreTabbingStatus(preTabbingStatus);
  } catch {
    sweetAlert.error("Ocorreu um erro ao solicitar os status de pre tabulação. Contate o suporte!");
  }
}

export const getLastCallData = async () => {
  return await request({
      method: "GET",
      path: `get-call-data`,
      showSuccessMessage: false,
      showLoading: false,
      showErrorMessage: false,
    });
}
