import React, { useState } from 'react';
import { useSocket } from 'hooks';
import { MdAdd, MdCheck } from 'react-icons/md';
import { IoMdTrash } from 'react-icons/io';
import MaskedInput from 'react-text-mask';
import { phoneMask } from 'utils/masks';
import { phoneMask as phoneModel, removeSpecialCharactersString } from 'utils';

import { AddContainer, InputContainer, NumberContainer, Wrapper } from './styles';
import { addMoreContacts, removeMoreContacts } from 'services/attendant';

const AddMoreNumbers: React.FC = () => {
  const { actualCall } = useSocket();
  
  const [isAdding, setIsAdding] = useState(false);
  const [numbers, setNumbers] = useState<string[]>(actualCall?.lead?.moreContacts || []);
  const [actualNumber, setActualNumber] = useState<string>("");

  const handleSave = async () => {
    const number = removeSpecialCharactersString(actualNumber);

    if (numbers.includes(number)) {
      setIsAdding(false);
      setActualNumber("");
      return;
    }

    if (number.length === 11 && actualCall?.lead._id) {
      addMoreContacts(actualCall?.lead._id, number);
      setNumbers(numbers => [...numbers, number]);
      setIsAdding(false);
      setActualNumber("");
    }
  }

  const handleRemove = async (number: string) => {
    if (numbers.includes(number) && actualCall?.lead._id) {
      const actualNumbers = numbers.filter(item => item !== number);
      setNumbers(actualNumbers);      
      removeMoreContacts(actualCall?.lead._id, number);
    }
  }

  return (
    <Wrapper>
      <AddContainer>
        <h5>Adicionar números extras</h5>
        {!isAdding &&
          <button onClick={() => setIsAdding(true)}>
            <MdAdd size={24} color="#ffffff"/>
          </button>
        }

        {isAdding && <InputContainer>
          <MaskedInput 
            mask={phoneMask()} 
            placeholder="Número" 
            value={actualNumber}
            onChange={(e) => setActualNumber(e.target.value)}
          />
          <button onClick={() => handleSave()}>
            Adicionar
          </button>
          <button onClick={() => {
            setIsAdding(false)
            setActualNumber("");
          }}>
            Cancelar
          </button>
        </InputContainer>}
      </AddContainer>

      {numbers.map(number => {
        return (
          <NumberContainer key={number}>
            <p>{phoneModel(number)}</p>
            <IoMdTrash 
              color='#ffffff' 
              size={24} 
              onClick={() => handleRemove(number)}
            />
          </NumberContainer>
        )
      })}
    </Wrapper>
  );
}

export default AddMoreNumbers;