import { FormEvent, useCallback, useEffect, useState } from "react";
import { Clock } from "components/Clock";
import debounce from 'lodash.debounce';
import { useAuth } from "hooks/auth";
import { ipcIsLoggedOut } from "utils/ipcevents";
import { AuthenticateUser, verifyUserExistence } from "services/login";
import { clearLs, setLsUserToken } from "../../localStorage/index";
import { handleEnteringError, handleEnteringSuccess, handleIncompatiblePasswords } from "./helper";
import PasswordField from "./PasswordField";
import FormButtons from "./FormButtons";

import {
  AuthContainer,
  AuthForm,
  AuthWrapper,
  ClockContainer,
  ErrorContainer,
  FormLabel,
  InputForm,
  LogoComponent,
} from "./styles";
import { createLog } from "services/logs";

const variantsContainerAnimation = {
  open: { x: -25, y: 35 },
  closed: { x: 0, y: 0},
}

export const Login: React.FC = () => {
  const [firstAcess, setFirstAcess] = useState<boolean>(false);
  const [isEntering, setIsEntering] = useState<boolean>(false);
  const [isUserFetched, setIsUserFetched] = useState<boolean>(false);
  const [fieldMessage, setFieldMessage] = useState({
    status: "Idle",
    message: "Faça seu login"
  });
  const [isFormOnError, setIsFormOnError] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const [isFormSucess, setIsFormSuccess] = useState<boolean>(false);
  const [isFormError, setIsFormError] = useState<boolean>(false);

  const [usernameField, setUsernameField] = useState<string>("");
  const [passwordField, setPasswordField] = useState<string>("");
  const [passwordConfirmField, setPasswordConfirmField] =  useState<string>("");

  const { verifyUserExists } = useAuth();

  useEffect(() => {
    ipcIsLoggedOut();
  }, [])

  const checkUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const userVerified = await verifyUserExistence(e.target.value);
      setIsUserFetched(true);

      if (!!userVerified.setPassword) {
        setFirstAcess(true);
      } else {
        setFirstAcess(false);
      }

      setUsernameField(e.target.value);
    } catch {
      setUsernameField(e.target.value);
    }
  }

  const handlerSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (passwordField === "" || usernameField === "") {
      return;
    }

    if ((passwordConfirmField === "" ||
        passwordField === "" ||
        usernameField === "") &&
        firstAcess) {
      return;
    }

    if (passwordField !== passwordConfirmField && firstAcess) {
      await handleIncompatiblePasswords(
        setIsEntering,
        setIsFormError,
        setIsFormOnError,
        setIsAnimating,
        setFieldMessage
      );
      return;
    }

    setIsEntering(true);
    setIsFormError(false);
    setFieldMessage({status: "Idle", message: ""});

    try {
      const response = await AuthenticateUser(usernameField, passwordField, firstAcess);
      await handleEnteringSuccess(
        setIsFormOnError,
        setIsAnimating,
        setIsFormSuccess
      );

      if (response.access_token) {
        setTimeout(async () => {
          clearLs();
          setLsUserToken(response.access_token);
          await verifyUserExists();
          await createLog().then(res => sessionStorage.setItem("logId", res._id));
          window.location.href = "/sistema";
        }, 1000);
      }
    } catch (error) {
      await handleEnteringError(
        setIsEntering,
        setIsFormError,
        setIsFormOnError,
        setIsAnimating,
        setFieldMessage,
        error
      );
    }
  };

  const renderNewUserPassword = useCallback(() => {
    if (firstAcess) {
      return (
        <>
          <PasswordField
            placeholder="Senha"
            onChange={(event) => {
              setIsFormError(false);
              setPasswordField(event)
            }}
            isEntering={isEntering}
          />
          <PasswordField
            placeholder="Confimar Senha"
            onChange={(event) => {
              setIsFormError(false);
              setPasswordConfirmField(event);
            }}
            isEntering={isEntering}
          />
        </>
      )
    }

    return (
      <InputForm
        type="password"
        placeholder="Senha"
        onChange={e => {
          setIsFormError(false);
          setPasswordField(e.target.value)
        }}
        isEntering={isEntering}
      />
    )
  }, [firstAcess, isEntering]);

  return (
    <AuthWrapper>
      <ClockContainer>
        <Clock mask={true} />
      </ClockContainer>

      <ErrorContainer
        error={isFormOnError.toString()}
        animate={isAnimating ? "open" : "closed"}
        variants={variantsContainerAnimation}
        transition={{ type: "Tween", duration: 0.25 }}
      />

      <AuthContainer>
        <LogoComponent />
        <FormLabel
            authStatus={fieldMessage.status}
            isEntering={isEntering}>
            {fieldMessage.message}
        </FormLabel>

        <AuthForm onSubmit={handlerSubmit}>
          {isEntering && <p>{isFormSucess ? "Bom trabalho!" : "Entrando..."}</p>}
            <InputForm
              type="text"
              placeholder="Usuário"
              onChange={debounce((event) => checkUser(event), 500)}
              isEntering={isEntering}
            />

          {isUserFetched && renderNewUserPassword()}
          <FormButtons isFormError={isFormError} isFormSucess={isFormSucess} isEntering={isEntering} />
        </AuthForm>
      </AuthContainer>
    </AuthWrapper>
  );
};
