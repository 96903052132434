import { ICall } from "interfaces/ICall";
import IStatusUser from "interfaces/IStatusUser";
import { Store } from "pullstate";
import { StatusConstants } from "utils/constants";

interface IProps {
  status: string;
  lastCalls: ICall[];
  pauseStatus: string[];
  lastStatus: IStatusUser[];
  showingNotes: boolean;
  notes: string;
  pauseQueue: {
    isOnPauseQueue: boolean;
    pauseReason: string;
  };
  prePause: boolean;
  micStream: MediaStream | null;
  preTabbingStatus: string[];
  isLogged: boolean;
}

export const UserStore = new Store<IProps>({
   status: StatusConstants.INACTIVE,
   lastCalls: [],
   pauseStatus: [],
   showingNotes: false,
   notes: localStorage.getItem("@notes") || "",
   pauseQueue: {
    isOnPauseQueue: false,
    pauseReason: "",
  },
    prePause: false,
   lastStatus: [],
   micStream: null,
   preTabbingStatus: [],
   isLogged: false,
});
