import ISchedules from "interfaces/ISchedules";
import React from "react";
import { getSchedules, loginAsterisk, makeRedialCall, removeSchedule } from "services/attendant";
import { setCallCreated, setCallType, setInCallData, setSipCallData } from "store/call/actions";
import { setActualCall } from "store/socket/actions,";
import { UserStore } from "store/user";
import { setIsLogged, setUserStatus } from "store/user/actions";
import { sleep } from "utils";
import { StatusConstants } from "utils/constants";
import { closeActualModal, closeScheduleModal, closeSchedulesModal, setActualModal } from "utils/popups";
import SchedulesModal from "..";

export const callScheduleNow = async (schedule: ISchedules) => {
  try {
    const tabbingStatus = {
      type: "rediscar"
    }
    setCallType('REDISCAGEM');

    if (!UserStore.getRawState().isLogged) {
      await loginAsterisk();
      setIsLogged(true);
      await sleep(1000);
    }

    setActualCall(undefined);
    setSipCallData(undefined);
    setUserStatus(StatusConstants.ATTENDANCE); 
    await makeRedialCall(tabbingStatus, schedule.callId._id, "");
    await removeSchedule(schedule._id, 'CALL');
    setCallCreated({_id: schedule.callId._id })
    closeSchedulesModal(true);
  } catch {
    console.log("Ocorreu um erro ao realizar a chamada");
  }
}

export const cancelSchedule = async (schedule: ISchedules) => {
  try {
    await removeSchedule(schedule._id, 'DELETE');
    const schedulingResponse = await getSchedules();
    setActualModal(React.createElement(SchedulesModal, { schedules: schedulingResponse }, null), true, "50%", closeScheduleModal);
  } catch {
    console.log("Ocorreu um erro ao cancelar o agendamento");
  }
}