import { Registerer, UserAgent } from "sip.js";

import IConnectCredentials from "interfaces/IConnectCredentials";
import sweetAlert from "utils/sweetAlert";
import { CallStore } from "store/call";
import { setCallCreated, setCallSession, setConnected, setInCall, setRamal, setSipCallData, setSipJsUserAgent } from "store/call/actions";
import { setMicStream, setUserStatus } from "store/user/actions";
import { StatusConstants } from "utils/constants";
import { SocketStore } from "store/socket";
import { UserStore } from '../../store/user/index';
import { ipcWindowShow } from "utils/ipcevents";
import { createNewCall } from "services/attendant";

let path = "./bip.mp3";
let audio = new Audio(path);

enum headersEnum {
  "X-Queue" = "X-Queue",
  "X-Tronco" = "X-Tronco",
  "X-Idhistory" = "X-Idhistory",
  "X-Info" = "X-Info",
  "X-Callurl" = "X-Callurl",
  "X-Type" = "X-Type",
  "X-Numero" = "X-Numero",
  "Contact" = "Contact",
}

function colorMicBar(vol: string) {
  const elem = document.getElementById("micBar");
  if (!elem) return;
  elem.style.width = vol;
}

const verifyPermissionsFunc = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true }).then(stream => {
        setMicStream(stream);
        const audioContext = new AudioContext();
        const audioSource = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        const decib = 100;
        analyser.fftSize = 512;
        analyser.minDecibels = -decib;
        analyser.maxDecibels = 0;
        analyser.smoothingTimeConstant = 0.7;
        audioSource.connect(analyser);
        const volumes = new Uint8Array(analyser.frequencyBinCount);
        setInterval(() => {
          analyser.getByteFrequencyData(volumes);
          let volumeSum = 0;
          for(const volume of volumes)
            volumeSum += volume;
          const averageVolume = volumeSum / volumes.length;
          if (!CallStore.getRawState().micMuted) {
            colorMicBar((averageVolume * 100 / decib) + '%');
          } else {
            colorMicBar(0 + '%');
          }
        }, 50);
      });
      return true;
    } catch (e) {
      sweetAlert.error(
        "Precisa liberar permissão de microfone no navegador, pode clicar na barra de endereço um pouco antes do http."
      );
      return false;
    }
};

const getValueByHeader = (
  headers: { [key: string]: { raw: string; parsed: string }[] },
  header: string
) => {
  let find = headers[header];

  if (!find || find[0].raw === "") return null;

  return find[0].raw;
};

const getLeadInfosAndCreatCallRequest = async (session: any) => {
  console.log("Pegando os valores dos headers");

  if (!session?.request) {
    let data: any = {
      sip: CallStore.getRawState().ramal,
      type: null,
      queue: null,
      phone: "Não listado",
      tronco: null,
      idhistory: null,
      info: null,
      callUrl: null,
    };

    setSipCallData(data);

    return;
  };

  let queue = getValueByHeader(
    session.request.headers,
    headersEnum["X-Queue"]
  );
  let tronco = getValueByHeader(
    session.request.headers,
    headersEnum["X-Tronco"]
  );
  let history = getValueByHeader(
    session.request.headers,
    headersEnum["X-Idhistory"]
  );
  let info = getValueByHeader(session.request.headers, headersEnum["X-Info"]);
  let callRecordUrl = getValueByHeader(
    session.request.headers,
    headersEnum["X-Callurl"]
  );
  let type = getValueByHeader(session.request.headers, headersEnum["X-Type"]);
  let phone = getValueByHeader(
    session.request.headers,
    headersEnum["X-Numero"]
  );

  let data: any = {
    sip: CallStore.getRawState().ramal,
    type,
    queue,
    phone,
    tronco,
    idhistory: history,
    info: JSON.parse(info || "[]"),
    callUrl: callRecordUrl,
  };

  if (!data.phone) {
    const contact = session.request.headers.From[0].parsed.uri.user;
    data.phone = contact;
  }

  console.log(CallStore.getRawState().type);
  console.log(tronco);

  if (CallStore.getRawState().type === 'FILA' || !!tronco) {
    try {
      const callCreated = await createNewCall(data);
      setCallCreated(callCreated);
    } catch {
      sweetAlert.error("Não foi possível criar a chamada. Por favor, contate o suporte!");
    }
  }

  console.log("OBJETO DE HEADERS DO SIP.JS", data);
  setSipCallData(data);
};

const setupRemoteMedia = async (session: any) => {
  console.log("montando o audio");
  if (!session || Object.keys(session).length === 0) return;
  session?.stateChange?.addListener((event: any) => {
    console.log(event);
    if (event === "Terminated") {
      setInCall(false);
      setCallSession(null);
      setUserStatus(StatusConstants.TABBING);
    }
  });

  console.log(`Headers Enviados: `, session?.request?.headers);
  console.log("Adicionando a voz no elemento de audio");
  const remoteStream = new MediaStream();
  console.log(
    session.sessionDescriptionHandler.peerConnection.getReceivers()
  );
  session.sessionDescriptionHandler.peerConnection
    .getReceivers()
    .forEach((receiver: any) => {
      if (receiver.track) {
        remoteStream.addTrack(receiver.track);
      }
    });

  var mediaElement = document.getElementById(
    "sip-provider-audio"
  ) as HTMLAudioElement;
  console.log(mediaElement);

  mediaElement.remove();

  var rootElement = document.getElementById("root") as HTMLAudioElement;
  mediaElement.srcObject = remoteStream;
  rootElement.appendChild(mediaElement);
  mediaElement.play();
};

export const callReceivedBySocket = async (call: any) => {
  try {
    setSipCallData(undefined);
    console.debug("Aceitando a ligação");

    window.focus();
    audio.play();

    setInCall(true);
    setupRemoteMedia(CallStore.getRawState().callSession);
    getLeadInfosAndCreatCallRequest(CallStore.getRawState().callSession);
    setUserStatus(StatusConstants.ATTENDANCE);
  } catch (e) {
    console.error("Ocorreu algum erro ao montar a ligação", e);
  }
};

export const connectAsterisk = async ({
  sipUri,
  ws,
  password,
  realm,
  displayName,
}: IConnectCredentials) => {
  try {
    let permission = await verifyPermissionsFunc();
    if (!permission) return;

    const uaConfig = {
      uri: UserAgent.makeURI(sipUri),
      transportOptions: {
        server: ws,
        traceSip: false,
        connectionTimeout: 3000,
      },
      displayName,
      authorizationUsername: displayName,
      authorizationPassword: password,
      stunServers: "stun:stun.l.google.com:19302",
      traceSip: true,
      contactParams: { transport: "wss" },
      delegate: {
        onInvite: (invite: any) => {
          console.log(
            "Convite recebido, aguardando webhook fazer a chamada ao socket: ",
            invite
          );

          const isTabbing = UserStore.getRawState().status === StatusConstants.TABBING;
          const isPrePause = UserStore.getRawState().prePause;
          const callStore = CallStore.getRawState();

          if (callStore.inCall || isPrePause || callStore.inPause || isTabbing) {
            console.log("Em ligação, convite rejeitado");
            invite.reject();
            return;
          }

          const status = UserStore.getRawState().status;
          if (StatusConstants.ERROR === status) {
            console.log("Ocorreu um erro durante a solicitação do convite");
            invite.reject();
            return;
          }
          console.log("Setando a session da ligação e aceitando o convite");
          invite
            .accept()
            .then(() => {
              console.log("convite aceito");
              sessionStorage.setItem('dynamicFields', '')
              sessionStorage.setItem('statusFields', '')
              setSipCallData(undefined);
              callReceivedBySocket(invite);
            })
            .catch((e: any) => console.log("erro ao aceitar convite", e));
          console.log(invite);
          setCallSession(invite);
          ipcWindowShow();
        },
      },
    };

    // SimpleUser construction
    const ua = new UserAgent(uaConfig);
    let register = new Registerer(ua);
    setSipJsUserAgent(ua);
    await ua.start();
    await register.register();

    register.stateChange.addListener((event) => {
      if (event === "Registered") {
        setConnected(true);
        if (!SocketStore.getRawState().actualCall) {
          setUserStatus(StatusConstants.WAITING);
        }
        setRamal(displayName);
      } else {
        setUserStatus(StatusConstants.ERROR)
      }
    });

    ua.stateChange.addListener((event) => {
      console.log(event);
    });
  } catch (e: any) {
    console.log(`Erro ao conectar no sip: ${e.message}`);
    setUserStatus(StatusConstants.ERROR)
    sweetAlert.error(
      "Ocorreu algum erro ao se conectar ao servidor do discador"
    );
  }
}
