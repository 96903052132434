import React, { useCallback, useMemo } from 'react';
import { useCallHandler, useSocket } from 'hooks';

import {ReactComponent as UserIcon} from "assets/icons/ico-user.svg";
import {ReactComponent as PhoneIcon} from "assets/icons/ico-phone.svg";

import { AtiveLabel, Container, InformationContainer, Wrapper } from './styles';
import AttendanceBottomMenu from '../AttendanceBottomMenu';
import AddMoreNumbers from './AddMoreNumbers';
import DynamicFields from './DynamicFields';
import LeadsFields from './LeadsFields';

const AttendanceSection: React.FC = () => {
  const { actualCall } = useSocket();
  const { approached, sipCallData, ramal } = useCallHandler();

  const phoneChooser = () => {
    if (!!sipCallData?.phone) return sipCallData?.phone;
    if (actualCall?.webhookCall?.phone) return actualCall?.webhookCall?.phone;

    return "Não listado";
  };

  return (
    <Wrapper>
      <Container>
        <AtiveLabel>ATIVO: {ramal}</AtiveLabel>

        <InformationContainer>
          <UserIcon />
          <h1>{actualCall?.lead?.data?.name ||actualCall?.lead?.data?.Nome || ""}</h1>
        </InformationContainer>

        <InformationContainer>
          <PhoneIcon />
          <h2>{phoneChooser()}</h2>
        </InformationContainer>

        {Object.keys(sipCallData?.info || []).map((infoKey) => {
          return (
            <InformationContainer key={infoKey}>
              {infoKey}: {sipCallData?.info[infoKey]}
            </InformationContainer>
          );
        })}

        {approached && <LeadsFields/>}
        {approached && <AddMoreNumbers />}
        {approached && <DynamicFields />}
      </Container>
      <AttendanceBottomMenu />
    </Wrapper>
  );
}

export default AttendanceSection;
