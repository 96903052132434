import { ICall } from "interfaces/ICall"
import ISchedulingDate from "interfaces/ISchedulingDate";
import { getLastCallData, getSchedules, makeRedialCall, makeSchedulingCall, saveDynamicFields, saveTabbing, unPauseQueue } from "services/attendant"
import { SocketStore } from "store/socket";
import { UserStore } from "store/user";
import { setUserStatus } from "store/user/actions";
import { callReceivedBySocket } from "utils/asterisk";
import { StatusConstants } from "utils/constants";
import sweetAlert from "utils/sweetAlert";
import { setIsOnPauseQueue } from 'store/user/actions';
import { setAttendantPause } from "components/RightPanel/PlayContainer/helper";
import { setApproached, setCallCreated, setCallType, setInPause, setSipCallData } from "store/call/actions";
import { closeScheduleModal, setActualModal } from "utils/popups";
import { setActualCall, setCallWithoutTabbing } from "store/socket/actions,";
import SchedulesModal from "components/SchedulesModal";
import React from "react";
import { CallStore } from "store/call";
import { tabbingCallLog } from "services/logs";

interface IFinalizeCallTab {
  tabbingStatus: any;
  callData?: ICall;
  observations: string;
}

export const finalizeCallTab = async ({
  tabbingStatus,
  observations,
  } : IFinalizeCallTab) => {
  try {
    let callData = CallStore.getRawState().callCreated;
    const callType = CallStore.getRawState().type;

    const callApproached = SocketStore.getRawState().actualCall;
    const dynamicFields = JSON.parse(sessionStorage.getItem('dynamicFields') || '[]')
    const statusFields = JSON.parse(sessionStorage.getItem('statusFields') || '[]')

    if (!callData?._id && callType === 'FILA') {
      sweetAlert.error("Registro da ligação não criado! Contate o suporte.");
      return;
    }

    if (!callData?._id && callType === 'REDISCAGEM') {
      callData = await getLastCallData();
    }

    if(callApproached?._id) {
      await saveDynamicFields(
        callApproached?.client._id,
        callApproached?._id,
        callApproached?.user._id,
        callApproached?.lead._id,
        statusFields,
        dynamicFields
      );
    }

    await tabbingCallLog(
      sessionStorage.getItem('logId'),
      callApproached?._id,
      callApproached?.lead._id,
      tabbingStatus?.status
    )

    await saveTabbing(tabbingStatus, callData, observations, dynamicFields);

    setCallCreated(undefined);
    if (!!SocketStore.getRawState().callWithoutTabbing) {
      window.location.reload();
      return;
    }

    const pauseQueue = UserStore.getRawState().pauseQueue;

    if (pauseQueue.isOnPauseQueue) {
      setInPause(true);
      setAttendantPause(pauseQueue.pauseReason);
      setUserStatus(StatusConstants.PAUSED);
      setIsOnPauseQueue(false, "");

      return;
    }

    const schedulingResponse = await getSchedules();

    if (schedulingResponse.length > 0) {
      setActualModal(React.createElement(SchedulesModal, { schedules: schedulingResponse }, null), true, "50%", closeScheduleModal);
    } else {
      setCallType('FILA');
      setSipCallData(undefined);
      setUserStatus(StatusConstants.WAITING);
      setTimeout(() => {
        unPauseQueue();
        console.log('Atendente Devolvido a Fila.');
      }, 5000);
    }

    setApproached(false);
    setActualCall(undefined);
    setCallWithoutTabbing(undefined);

  } catch {
    // sweetAlert.error("Ocorreu um erro ao tabular");
  }
}

export const redialCallTab = async (observations: string) => {
  try {
    const tabbingStatus = {
      label: 'Rediscar',
      status: 'Rediscar',
      type: 'rediscar',
    }
    setCallType('REDISCAGEM');

    let callData = SocketStore.getRawState().actualCall;
    const dynamicFields = JSON.parse(sessionStorage.getItem('dynamicFields') || '[]')
    const statusFields = JSON.parse(sessionStorage.getItem('statusFields') || '[]')

    if (!callData) {
      callData = await getLastCallData();
    }

    if(callData?._id) {
      await saveDynamicFields(
        callData?.client._id,
        callData?._id,
        callData?.user._id,
        callData?.lead._id,
        statusFields,
        dynamicFields,
      );
    }

    await tabbingCallLog(
      sessionStorage.getItem('logId'),
      callData?._id,
      callData?.lead._id,
      tabbingStatus?.status
    )

    setUserStatus(StatusConstants.ATTENDANCE);
    setApproached(false);
    setCallCreated(undefined);
    setActualCall(undefined);
    setCallWithoutTabbing(undefined);
    await makeRedialCall(tabbingStatus, callData._id, observations, dynamicFields);
    callReceivedBySocket(callData);
  } catch {
    // sweetAlert.error("Ocorreu um erro ao rediscar");
  }
}

export const schedulingCallTab = async (
  schedulingDate: ISchedulingDate,
  observations: string,
  tabbingStatusSelected: any,
  ) => {
  try {
    let callApproached = SocketStore.getRawState().actualCall;
    const dynamicFields = JSON.parse(sessionStorage.getItem('dynamicFields') || '[]')
    const statusFields = JSON.parse(sessionStorage.getItem('statusFields') || '[]')

    const tabbingStatus = {
      label: tabbingStatusSelected?.label,
      status: tabbingStatusSelected?.status,
      type: tabbingStatusSelected?.type,
    }

    if (!tabbingStatusSelected.label || !tabbingStatusSelected.status) {
      tabbingStatus.label = "Agendamento Pessoal";
      tabbingStatus.status = "Agendamento Pessoal";
    }

    if (!tabbingStatus.type) {
      tabbingStatus.type = "ag-pessoal";
    }

    let callData = CallStore.getRawState().callCreated;
    const callType = CallStore.getRawState().type;

    if (!callData?._id && callType === 'FILA') {
      sweetAlert.error("Registro da ligação não criado! Contate o suporte.");
      return;
    }

    if (callType === 'REDISCAGEM') {
      if (CallStore.getRawState().approached) {
        callData = SocketStore.getRawState().actualCall;
      } else {
        callData = await getLastCallData();
      }
    }

    if(callApproached?._id) {
      await saveDynamicFields(
        callApproached?.client._id,
        callApproached?._id,
        callApproached?.user._id,
        callApproached?.lead._id,
        statusFields,
        dynamicFields,
      );
    }

    await tabbingCallLog(
      sessionStorage.getItem('logId'),
      callApproached?._id,
      callApproached?.lead._id,
      tabbingStatus?.status
    )

    await makeSchedulingCall(
      tabbingStatus,
      schedulingDate,
      callData,
      observations,
      dynamicFields
    );

    if (!!SocketStore.getRawState().callWithoutTabbing) {
      window.location.reload();
      return;
    }

    const pauseQueue = UserStore.getRawState().pauseQueue;

    if (pauseQueue.isOnPauseQueue) {
      setUserStatus(StatusConstants.PAUSED);
      setAttendantPause(pauseQueue.pauseReason);
      setIsOnPauseQueue(false, "");
      setInPause(true);

      return;
    }

    const schedulingResponse = await getSchedules();
    if (schedulingResponse.length > 0) {

      console.log('CREATING ELEMENT');
      
      const modalElement = React.createElement(SchedulesModal, { schedules: schedulingResponse }, null);
      setActualModal(modalElement, true, "50%", closeScheduleModal);
    } else {
      setUserStatus(StatusConstants.WAITING);
      setTimeout(() => {
        unPauseQueue();
        console.log('Atendente Devolvido a Fila.');
      }, 5000);
    }

    setApproached(false);
    setActualCall(undefined);
    setCallWithoutTabbing(undefined);
  } catch {
    // sweetAlert.error("Ocorreu um erro ao agendar");
  }
}
