import React from 'react';
import ISchedules from 'interfaces/ISchedules';
import { callScheduleNow, cancelSchedule } from './helper';
import { Container, ScheduleOptionButton, Wrapper } from './styles';

interface IProps {
  schedule: ISchedules;
}

const ScheduleItem: React.FC<IProps> = ({ schedule }: IProps) => {

  const namePicker = () => {
    if (schedule?.callId?.lead?.data?.name) return schedule?.callId?.lead?.data?.name;
    if (schedule?.callId?.lead?.data?.Nome) return schedule?.callId?.lead?.data?.Nome;

    return schedule?.callId?.client?.name;
   }

  return(
    <Wrapper>
      <Container width="30%">{namePicker()}</Container>
      <Container width="10%">{schedule?.scheduleHour}</Container>
      <Container width="20%">{schedule?.scheduleDate}</Container>
      <Container width="20%">
        <ScheduleOptionButton onClick={() => callScheduleNow(schedule)}>
          Ligar Agora
        </ScheduleOptionButton>
      </Container>
      <Container width="20%">
        <ScheduleOptionButton isCancel onClick={() => cancelSchedule(schedule)}>
          Cancelar
        </ScheduleOptionButton>
      </Container>
    </Wrapper>
  );
}

export default ScheduleItem;