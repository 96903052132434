const initialState = {
  editField: null,
  fieldValue: null,
};

export const stateField = (state = initialState, action:any) => {
  switch (action.type) {
    case 'SET_EDIT_FIELD':
      return {
        ...state,
        editField: action.editField,
      };
    case 'SET_FIELD_VALUE':
      return {
        ...state,
        fieldValue: action.fieldValue,
      };
    default:
      return state;
  }
}
