import { useSocket, useUser } from "hooks";
import { useEffect } from "react";
import { approachedCallLog, awaitingCallLog, inCallLog, pauseCallLog, tabbingCallLog, unPauseCallLog } from "services/logs";
import { StatusConstants } from 'utils/constants';

export const Logs = () => {
  const { status } = useUser();
  const { actualCall } = useSocket();

  const logId: any = sessionStorage.getItem('logId');
  const callId = actualCall?._id;
  const leadId = actualCall?.lead?._id;

  useEffect(() => {
    switch (status) {
      case StatusConstants.WAITING:
        awaitingCallLog(logId);
        break;

      case StatusConstants.ATTENDANCE:
        inCallLog(logId, callId, leadId);
        break;

      case StatusConstants.APPROACHED:
        approachedCallLog(logId, callId, leadId);
        break;

      case StatusConstants.TABBING:
        tabbingCallLog(logId, callId, leadId);
        break;

      case StatusConstants.PAUSED:
        pauseCallLog(logId);
        break;

      case StatusConstants.UNPAUSE:
        unPauseCallLog(logId);
        break;

      default:
        break;
    }
  }, [status, callId, leadId]);

  return (
    <></>
  )
}
