import { request } from "services/request";
import IVerifyUser from 'interfaces/IVerifyUser';

const verifyUserExistence = async (username: string): Promise<IVerifyUser> => {
  return await request({
    method: "POST",
    path: `verify-login-attendant`,
    data: {
      login: username
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showLoading: false,
  });
}

const AuthenticateUser = async (userName: string, password: string, firstAccess: boolean) => {
  return await request({
    showLoading: false,
    showSuccessMessage: false,
    showErrorMessage: true,
    method: "POST",
    path: `login`,
    data: {
      login: `${userName}`,
      password: password,
      firstAccess,
    },
  });
}

const verifyPassword = async (password: string) => {
  return request({
    showLoading: false,
    showSuccessMessage: false,
    showErrorMessage: false,
    method: "POST",
    path: `verify-password`,
    data: {
      password,
    },
  });
}

export { verifyUserExistence, AuthenticateUser, verifyPassword }
