import React from 'react';
import { finalizeCallTab, redialCallTab } from './helper';
import SchedulingModal from './SchedulingModal';

import {
  ActiveBorder,
  RedialIco,
  Container,
  MessageIcon,
  MenuCell,
  CalendarIco,
  Wrapper,
  HangUpIco,
} from './styles';
import { setActualModal } from 'utils/popups';

interface IProps {
  tabbingStatus: any,
  observations: string;
}

const TabbingBottomMenu: React.FC<IProps> = ({ tabbingStatus, observations}: IProps) => {
  return (
    <Wrapper>
      <Container>
        <MenuCell active >
          <MessageIcon/>
          <p>Tabular</p>
          <ActiveBorder />
        </MenuCell>
        {tabbingStatus?.type === "rediscar" && <MenuCell onClick={() => redialCallTab(observations)}>
          <RedialIco />
          <p>Rediscar</p>
        </MenuCell>}
        {["ag-pessoal", "ag-base"].includes(tabbingStatus?.type) &&
          <MenuCell onClick={() => {
              setActualModal(<SchedulingModal
                              observations={observations}
                              tabbingStatus={tabbingStatus}
                            />, true, '40%');
            }
          }>
            <CalendarIco />
            <p>Agendar</p>
          </MenuCell>}
        <MenuCell onClick={() => finalizeCallTab({
          tabbingStatus,
          observations,
        })}>
          <HangUpIco />
          <p>Finalizar</p>
        </MenuCell>
      </Container>
    </Wrapper>
  );
}

export default TabbingBottomMenu;
