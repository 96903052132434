import React from 'react';
import { verifyUserUseWebSoftphone,
         unPauseQueue,
         getSchedules,
         loginAsterisk,
         getCallWithoutTabbing,
         getPreTabbingStatus } from 'services/attendant';
import { createLog } from 'services/logs';
import { setActualCall, setCallWithoutTabbing } from 'store/socket/actions,';
import { setIsLogged, settPreTabbingStatus, setUserStatus } from "store/user/actions";
import { sleep } from 'utils';
import { connectAsterisk } from "utils/asterisk";
import { StatusConstants } from "utils/constants";
import { closeScheduleModal, setActualModal } from 'utils/popups';
import sweetAlert from 'utils/sweetAlert';
import SchedulesModal from 'components/SchedulesModal';
import { setCallCreated } from 'store/call/actions';

export const preConnectAsterisk = async (status?: string) => {
  try {
    setUserStatus(StatusConstants.CONNECTING);
    const responseSoftphone = await verifyUserUseWebSoftphone();

    const asteriskUrl = window.location.href.indexOf("stage") !== -1
          ? "pessoalize.gvctelecom.com.br:9070"
          : "pessoalize.gvctelecom.com.br:9070";

    if (status !== StatusConstants.ERROR) {
      connectAsterisk({
        ws: "wss://" + asteriskUrl + "/ws",
        sipUri: `sip:${responseSoftphone.ramal}@${asteriskUrl}`,
        password: `${responseSoftphone.ramal}@xyz`,
        realm: asteriskUrl.split(":")[0],
        displayName: responseSoftphone.ramal,
      });
    }
  } catch {
    console.log("Não foi possível se conectar ao discador");
  }
};

export const preConectTabbingStatus = async () => {
  const preTabbingStatus = await getPreTabbingStatus();
  settPreTabbingStatus(preTabbingStatus);
}

export const verifyScheduling = async () => {
  try {
    const schedulingResponse = await getSchedules();

    if (schedulingResponse.length > 0) {
      setActualModal(React.createElement(SchedulesModal, { schedules: schedulingResponse }, null), true, "50%", closeScheduleModal);
      return true;
    } else {
      await loginAsterisk();
      await sleep(1000);
      await unPauseQueue();
      setIsLogged(true);

      return false;
    }

  } catch {
    setUserStatus(StatusConstants.ERROR);
    // sweetAlert.error("Ocorreu um erro ao realizar o login!");
  }
}

export const verifyCallWithoutTabbing = async () => {
  try {
    const callWithoutTabbing = await getCallWithoutTabbing();

    if (!!callWithoutTabbing) {
      setCallWithoutTabbing(callWithoutTabbing);
      setActualCall(callWithoutTabbing);
      setCallCreated(callWithoutTabbing);
      setUserStatus(StatusConstants.TABBING);
      sweetAlert.error("Você tem uma ligação sem tabulação");
      console.log("Call without tabbing", callWithoutTabbing);
      return true;
    } else {
      return false;
    }
  } catch {
    setUserStatus(StatusConstants.ERROR);
    sweetAlert.error("Ocorreu um erro ao solicitar chamadas sem tabulação!");
  }
}
