import { loginAsterisk, unPauseQueue } from "services/attendant";
import { ModalStore } from "store/modal";
import { setCloseModalFunction, setModalChildren, setModalOpened, setModalWidth } from "store/modal/actions";
import { UserStore } from "store/user";
import { setIsLogged, setUserStatus } from "store/user/actions";
import { phoneMask, sleep } from "utils";
import { postponeCalculate, redialComplete } from "utils/calls";
import { StatusConstants } from "utils/constants";
import sweetAlert from "utils/sweetAlert";

export const schedulingPopup = async (schedulingResponse: any) => {
  await sweetAlert.confirm(
    `${schedulingResponse.client.name} | ${phoneMask(
      schedulingResponse.webhookCall.phone
    )} | ${schedulingResponse.tabbing.agDate} |
    ${schedulingResponse?.note}`,
    "Ligar agora",
    "Adiar",
    "Você tem uma ligação agendada em menos de cinco minutos",
    async () => {
      redialComplete(schedulingResponse);
    },
    async () => {
      await postponeCalculate(
        schedulingResponse
      );
      await sleep(3000);
      unPauseQueue();
      setUserStatus(StatusConstants.WAITING);
    }
  );
}

export const setActualModal = (component: React.ReactNode, opened=true, width='40%', closeFunction=() => {}) => {
  setModalOpened(opened);
  setModalChildren(component);
  setModalWidth(width);
  setCloseModalFunction(closeFunction);
}

export const closeActualModal = () => {
  const closeModalFunction = ModalStore.getRawState().closeModalFunction;
  if (closeModalFunction) closeModalFunction();
  unPauseQueue();
  setModalOpened(false);
}

export const closeScheduleModal = async () => {
  if (!UserStore.getRawState().isLogged) {
    await loginAsterisk();
    await sleep(1000);
    setIsLogged(true);
  }

  unPauseQueue();

  setUserStatus(StatusConstants.WAITING);
}

export const closeSchedulesModal = async (callNow: boolean) => {
  if (callNow) {
    setModalOpened(false);
  } else {
    if (!UserStore.getRawState().isLogged) {
      await loginAsterisk();
      await sleep(1000);
      setIsLogged(true);
    }

    await unPauseQueue();
    setUserStatus(StatusConstants.WAITING);
    setModalOpened(false);
  }
}

