import { Dispatch, SetStateAction } from "react";

export const handleEnteringError = async (
  setIsEntering: Dispatch<SetStateAction<boolean>>,
  setIsFormError: Dispatch<SetStateAction<boolean>>,
  setIsFormOnError: Dispatch<SetStateAction<boolean>>,
  setIsAnimating: Dispatch<SetStateAction<boolean>>,
  setFieldMessage: Dispatch<SetStateAction<{
    status: string;
    message: string;
  }>>,
  error: any,
) => {
    setIsEntering(false);
    setIsFormOnError(true);
    setIsAnimating(true);
    setFieldMessage({status: "Error", message: "Ocorreu um erro ao logar"});
    setIsFormError(true);
}

export const handleEnteringSuccess = async (
  setIsFormOnError: Dispatch<SetStateAction<boolean>>,
  setIsAnimating: Dispatch<SetStateAction<boolean>>,
  setIsFormSuccess: Dispatch<SetStateAction<boolean>>
) => {
    setIsFormOnError(false);
    setIsAnimating(true);
    setIsFormSuccess(true);

}

export const handleIncompatiblePasswords = async (
  setIsEntering: Dispatch<SetStateAction<boolean>>,
  setIsFormError: Dispatch<SetStateAction<boolean>>,
  setIsFormOnError: Dispatch<SetStateAction<boolean>>,
  setIsAnimating: Dispatch<SetStateAction<boolean>>,
  setFieldMessage: Dispatch<SetStateAction<{
    status: string;
    message: string;
  }>>
) => {

    setIsEntering(false);
    setIsFormOnError(true);
    setIsAnimating(true);
    setFieldMessage({status: "Error", message: "Senhas não compatíveis"});
    setIsFormError(true);

}
