import customSelectStyles from 'components/Select';
import React, { useEffect, useState } from 'react';
import { MdCall, MdRefresh } from 'react-icons/md';
import Select from 'react-select';

import { getAudits } from 'services/audits';
import { auditOptions } from 'utils/constants';
import {  makeAuditCall } from './helper';
import { AuditHeader, AuditReload, AuditsCard, AuditsContainer, AuditsInfos, ButtonsContainer, CallButton, SelectContainer, Wrapper } from './styles';

const AuditSection: React.FC = () => {
  const [audits, setAudits] = useState<any>([]);
  const [auditStatus, setAuditStatus] = useState<any>({
    type: 'Todos',
    label: 'Todos',
    status: 'Todos'
  });
  const [filteredAudits, setFilteredAudits] = useState([]);
  const [auditStatusToFilter, setAuditStatusToFilter] = useState(auditOptions);

  const auditsData = async () => {
    const audtisResponse = await getAudits();
    setAudits(audtisResponse);
    if (auditStatus.label !== 'Todos') {
      const auditsFilter = audtisResponse.filter((audit: any) => audit.status === auditStatus?.label);
      setFilteredAudits(auditsFilter);
    } else {
      setFilteredAudits(audtisResponse);
    }
  }

  useEffect(() => {
    if (auditStatus?.status === 'Todos') {
      setFilteredAudits(audits);
    }
  }, [auditStatus, audits]);

  useEffect(() => {
    auditsData();
    setAuditStatusToFilter(auditOptions => [{
      label: 'Todos',
      type: 'Todos',
      status: 'Todos',
    }, ...auditOptions])
  }, []);

  return (
    <Wrapper>
      <AuditHeader>
        <h1>Auditoria</h1>
        <AuditReload onClick={() => auditsData()}>
          <MdRefresh size={32} color="white" />
        </AuditReload>
      </AuditHeader>
      <SelectContainer>
        <Select
          options={
            auditStatusToFilter?.map(
              (t: any) => {
                return {
                  value: t,
                  label: t.status,
                };
              }
            )}
            styles={customSelectStyles}
            value={auditStatus}
            defaultValue={{
                label: 'Todos',
                type: 'Todos',
                status: 'Todos',
              }
            }
            placeholder="Filtrar por status"
            onChange={(v) => {
              setAuditStatus(
                v ? { ...v.value, label: v.label } : null
              );
              const auditsFilter = audits.filter((audit: { status: string; }) => audit.status === v?.label);
              setFilteredAudits(auditsFilter);
            }
          }
        />
      </SelectContainer>

      <AuditsContainer>
        {filteredAudits.map((audit: any, index: number) => {
          return (
            <AuditsCard key={index}>
              <div>
                <h4>{audit.lead.name}</h4>
                <h5>{audit.lead.telephones[0].telephone}</h5>
                <p>{audit?.status}</p>

                <AuditsInfos>
                  {Object.keys(audit.lead?.data || []).map((infoKey) => {
                    if (infoKey === 'dynamic_fields') {
                      return (<div key={infoKey} />)
                    }

                    return (
                      <p key={infoKey}>
                        {infoKey}: {audit.lead?.data[infoKey]}
                      </p>
                    );
                  })}
                </AuditsInfos>
              </div>
              <ButtonsContainer>
                <CallButton onClick={() => makeAuditCall(audit)}>
                  <MdCall size={ 24 } color="white"/>
                </CallButton>
              </ButtonsContainer>
            </AuditsCard>
          )
        })}
      </AuditsContainer>

    </Wrapper>
  );
}

export default AuditSection;