import { request } from "services/request";

export const createLog = (): Promise<any> => {
  return request({
    method: "POST",
    path: `create`,
    basePath: 'call-logs',
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

export const awaitingCallLog = (logId: string): Promise<any> => {
  return request({
    method: "POST",
    path: `awaiting-call-log`,
    basePath: 'call-logs',
    data: { logId },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

export const inCallLog = (
  logId: string,
  callId?: string,
  leadId?: string,
): Promise<any> => {
  return request({
    method: "POST",
    path: `in-call-log`,
    basePath: 'call-logs',
    data: {
      logId,
      callId,
      leadId
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

export const approachedCallLog = (
  logId: string,
  callId?: string,
  leadId?: string,
): Promise<any> => {
  return request({
    method: "POST",
    path: `approached-call-log`,
    basePath: 'call-logs',
    data: {
      logId,
      callId,
      leadId
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

export const tabbingCallLog = (
  logId: any,
  callId?: string,
  leadId?: string,
  tabbing?: string,
): Promise<any> => {
  return request({
    method: "POST",
    path: `tabbing-call-log`,
    basePath: 'call-logs',
    data: {
      logId,
      callId,
      leadId,
      tabbing
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

export const pauseCallLog = (logId: string): Promise<any> => {
  return request({
    method: "POST",
    path: `pause-call-log`,
    basePath: 'call-logs',
    data: { logId },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

export const unPauseCallLog = (logId: string): Promise<any> => {
  return request({
    method: "POST",
    path: `un-pause-call-log`,
    basePath: 'call-logs',
    data: { logId },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: false,
  });
}

