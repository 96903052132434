import { request } from "services/request";
import IUserUseWebSoftphone from "interfaces/IUserUseWebSoftphone";
import { ICall } from "interfaces/ICall";
import ISchedulingDate from "interfaces/ISchedulingDate";
import IStatusUser from "interfaces/IStatusUser";
import ISchedules from "interfaces/ISchedules";
import IDynamicFields from "interfaces/IDynamicFields";

const verifyUserUseWebSoftphone = async (): Promise<IUserUseWebSoftphone> => {
  return await request({
    method: "GET",
    path: `caller/verify-user-use-websoftphone`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const getLastCalls = async (): Promise<ICall[]> => {
  return await request({
    method: "GET",
    path: `get-latest-calls`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const pauseQueue = async (status: string): Promise<any> => {
  return await request({
    method: "POST",
    path: `set-pause`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
    data: {
      pause: status,
    },
  });
}

const unPauseQueue = async (): Promise<any> => {
  return await request({
    method: "POST",
    path: `remove-pause`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
    data: {},
  });
}

const getPauseStatus = async (): Promise<any> => {
  return await request({
    method: "GET",
    path: `attendant-pauses`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const getUserStatus = async (): Promise<IStatusUser[]> => {
  return await request({
    method: "GET",
    path: `get-status-user`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  })
}

const logoffAttendant = async (): Promise<any> => {
  return await request({
    method: "POST",
    path: `logout`,
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const saveTabbing = async (
  tabbingStatus: any,
  inCallData?: ICall | { _id: string },
  observations?: string,
  dynamicFields?: any,
  ): Promise<any> => {

  return await request({
    method: "POST",
    path: `${inCallData?._id}/save-tabbing-status`,
    data: {
      tabbingStatus,
      ag: false,
      retry: false,
      note: observations,
      dynamicFields: dynamicFields,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const makeRedialCall = async (
  tabbingStatus: any,
  callid: string,
  observations?: string,
  dynamicFields?: any,
  ): Promise<any> => {

  return await request({
    method: "POST",
    path: `${callid}/save-tabbing-status`,
    data: {
      tabbingStatus,
      ag: false,
      retry: true,
      note: observations,
      dynamicFields: dynamicFields,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const makeSchedulingCall = async (
  tabbingStatus: any,
  time: ISchedulingDate,
  inCallData?: ICall | { _id: string },
  observations?: string,
  dynamicFields?: any,
  ): Promise<any> => {
  return await request({
    method: "POST",
    path: `${inCallData?._id}/save-tabbing-status`,
    data: {
      tabbingStatus,
      ag: time,
      retry: true,
      note: observations,
      dynamicFields: dynamicFields,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const getSchedules = (): Promise<ISchedules[]> => {
  return request({
    method: "GET",
    path: `get-schedule-date`,
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
    showLoading: false,
  });
}

const removeSchedule = (scheduleId: string, type: string): Promise<any> => {
  return request({
    method: "POST",
    path: `remove-schedule`,
    data: {
      scheduleId,
      type,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
    showLoading: true,
  });
}

const approach = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `approach`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const getLastCallData = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `get-call-data`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const loginAsterisk = (): Promise<any> => {
  return request({
    method: "POST",
    path: `login-asterisk`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const getCallWithoutTabbing = (): Promise<ICall> => {
  return request({
    method: "GET",
    path: `get-info-calls-without-tabbing`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const getPreTabbingStatus = (): Promise<string[]> => {
  return request({
    method: "GET",
    path: `get-pre-tabbing-status`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const postponeCall = (callData: ICall): Promise<any> => {
  return request({
    method: "POST",
    path: `postpone-call`,
    data: {
      callId: callData._id,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
    showLoading: false,
  });;
}

const createNewCall = (data: any): Promise<any> => {
  return request({
    method: "POST",
    path: `save-new-call`,
    basePath:'webhook',
    data: {
      ...data
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
    showLoading: false,
  });
}

const addMoreContacts = (leadId: string, number: string): Promise<any> => {
  return request({
    method: "PUT",
    path: `add-contact/${leadId}`,
    data: {
      number: number,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
    showLoading: false,
  });
}

const removeMoreContacts = (leadId: string, number: string): Promise<any> => {
  return request({
    method: "PUT",
    path: `remove-contact/${leadId}`,
    data: {
      number: number,
    },
    showSuccessMessage: false,
    showErrorMessage: true,
    showWarningMessage: true,
    showLoading: false,
  });
}

const GetDynamicFields = (
  clientId?: string,
): Promise<IDynamicFields[]> => {
  return request({
    method: "GET",
    path: `get-fields/${clientId}`,
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
    showWarningMessage: true,
  }).then(
    (res) => res);
}

const getDynamicFieldsData = (
  clientId?: string,
  leadId?: string,
): Promise<any> => {
  return request({
    method: "GET",
    path: `get-fields-data/${clientId}?leadId=${leadId}`,
    basePath: 'fields',
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: false,
    showWarningMessage: true,
  })
}

const saveDynamicFields = (
  clientId?: string,
  callId?: string,
  userId?: string,
  leadId?: string,
  status?: string,
  data?: IDynamicFields[] | [],
): Promise<any> => {
  return request({
    method: "POST",
    path: `save-fields/${clientId}`,
    data: {
      callId,
      userId,
      leadId,
      status,
      data,
    },
    showSuccessMessage: false,
    showErrorMessage: false,
    showWarningMessage: true,
  });
}

const GetCampaignFields = (
  clientId?: string,
  campaignId?: string,
): Promise<any> => {
  return request({
    method: "GET",
    path: `get-fields-campaign/${clientId}?campaignId=${campaignId}`,
    basePath: 'campaign',
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const SaveCampaignFields = (
  leadId?: string,
  campaignId?: string,
  field?: string,
  data?: string,
): Promise<any> => {
  return request({
    method: "PUT",
    path: `update-lead-data/${leadId}?campaignId=${campaignId}`,
    basePath: 'leads',
    data: {
      field,
      data
    },
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

const saveDataLeadInCall = (
  callId?: string,
  leadId?: string,
  campaignId?: string,
  field?: string,
  data?: string,
): Promise<any> => {
  return request({
    method: "PUT",
    path: `update-lead-data/${callId}?leadId=${leadId}&campaignId=${campaignId}`,
    data: {
      field,
      data
    },
    showSuccessMessage: false,
    showLoading: false,
    showErrorMessage: true,
    showWarningMessage: true,
  });
}

export {
  verifyUserUseWebSoftphone,
  getLastCalls,
  pauseQueue,
  unPauseQueue,
  getPauseStatus,
  getUserStatus,
  logoffAttendant,
  saveTabbing,
  makeRedialCall,
  makeSchedulingCall,
  getSchedules,
  removeSchedule,
  approach,
  loginAsterisk,
  getCallWithoutTabbing,
  getPreTabbingStatus,
  getLastCallData,
  postponeCall,
  createNewCall,
  addMoreContacts,
  removeMoreContacts,
  GetDynamicFields,
  getDynamicFieldsData,
  saveDynamicFields,
  GetCampaignFields,
  SaveCampaignFields,
  saveDataLeadInCall,
}
