import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { getPayload } from "utils";

import { PrivateRoute } from "./PrivateRoute";

import { Dial } from "../pages/Dial";
import { Login } from "../pages/Login";
import Audit from "pages/Audit";
import Dialer from "pages/Dialer";
console.log( getPayload()?.userType?.name);

export const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" exact render={() => (
      getPayload()?.userType?.name === "Atendente" 
      ? (<Redirect to="/sistema" />) 
      : (<Login />)
    )}/>

    <Route path="/sistema" exact render={() => (
      getPayload()?.userType?.name === "Auditor" 
      ? (<Redirect to="/auditoria" />) 
      : (<Dialer />)
    )}/>

    <Route path="/sistema" exact component={Dialer} />
    <Route path="/auditoria" exact component={Audit} />
    <PrivateRoute path="/" exact component={Dialer} />
    <PrivateRoute path="/discador" exact component={Dial} />
  </Switch>
);
