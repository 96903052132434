import React, { useMemo, useState } from 'react';
import { useCallHandler, useSocket, useUser } from 'hooks';
import Select from "react-select";

import TabbingBottomMenu from './TabbingBottomMenu';

import {ReactComponent as UserIcon} from "assets/icons/ico-user.svg";
import {ReactComponent as PhoneIcon} from "assets/icons/ico-phone.svg";

import { Wrapper, AtiveLabel, Container, InformationContainer, SelectContainer, InputContainer } from './styles';
import customSelectStyles from 'components/Select';
import DynamicFields from '../AttendanceSection/DynamicFields';
import LeadsFields from '../AttendanceSection/LeadsFields';

const TabbingSection: React.FC = () => {
  const { actualCall, callWithoutTabbing } = useSocket();
  const { approached, sipCallData, ramal } = useCallHandler();
  const { preTabbingStatus } = useUser();
  const [tabbingStatus, setTabbingStatus] = useState();
  const [observations, setObservations] = useState("");

  const phoneChooser = useMemo(() => {
    if (!!sipCallData?.phone) return sipCallData?.phone;
    if (!!actualCall?.webhookCall?.phone) return actualCall?.webhookCall?.phone;

    return "Não listado";
  }, [actualCall?.webhookCall?.phone, sipCallData?.phone]);

  const tabbingStatusChooser = useMemo(() => {
    if (approached || callWithoutTabbing) return actualCall?.client?.tabbingStatus;

    return preTabbingStatus;
  }, [actualCall?.client?.tabbingStatus, approached, callWithoutTabbing, preTabbingStatus])

  return (
    <Wrapper>
      <Container>
        <AtiveLabel>ATIVO: {ramal}</AtiveLabel>

        <InformationContainer>
          <UserIcon />
          <h1>{actualCall?.lead?.data?.name || actualCall?.lead?.data?.Nome || ""}</h1>
        </InformationContainer>

        <InformationContainer>
          <PhoneIcon />
          <h2>{phoneChooser}</h2>
        </InformationContainer>

        {Object.keys(sipCallData?.info || []).map((infoKey) => {
          return (
            <InformationContainer key={infoKey}>
              {infoKey}: {sipCallData?.info[infoKey]}
            </InformationContainer>
          );
        })}

        {<LeadsFields/>}

        <SelectContainer>
          <Select
            options={
              tabbingStatusChooser?.map(
                (t: any) => {
                  return {
                    value: t,
                    label: t.status,
                  };
                }
              )}
              styles={customSelectStyles}
              value={tabbingStatus}
              placeholder="Selecione um status de tabulação"
              onChange={(v) =>
                setTabbingStatus(
                  v ? { ...v.value, label: v.label } : null
                )
              }
          />
        </SelectContainer>
        {(approached || callWithoutTabbing) && <DynamicFields />}
        <InputContainer>
          <p>Observações</p>
          <textarea onChange={(event) => setObservations(event.target.value)}/>
        </InputContainer>
      </Container>
      <TabbingBottomMenu
        tabbingStatus={tabbingStatus}
        observations={observations}
      />
    </Wrapper>
  );
}

export default TabbingSection;
