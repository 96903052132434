import { makeRedialCall } from "services/attendant";
import { newAuditCall } from "services/audits";
import { CallStore } from "store/call";
import { setActualAudit, setCallType } from "store/call/actions";
import { setUserStatus } from "store/user/actions";
import { auditOptions, StatusConstants } from "utils/constants";

export const makeAuditCall = async (audit: any) => {
  const tabbingStatus = {
    label: 'Rediscar',
    status: 'Rediscar',
    type: 'rediscar',
  }
  setCallType('REDISCAGEM');
  await newAuditCall(audit.callId);
  setActualAudit(audit);
  setUserStatus(StatusConstants.ATTENDANCE);
}

export const auditOptionsCustom = () => {
  const allStatus = {
    label: '',
    status: '',
    type: '',
  };

  const options = auditOptions;
  options.push(allStatus);

  return auditOptions;
}