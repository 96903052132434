/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import RightPanel from 'components/RightPanel';
import { calculateActualCoverImage } from 'utils';
import { useUser } from 'hooks';
import { CoverImage, Wrapper } from './styles';
import { preConectTabbingStatus, preConnectAsterisk, verifyCallWithoutTabbing, verifyScheduling } from './helper';
import Modal from 'components/Modal';
import { ipcIsLogged, ipcWindowShow } from 'utils/ipcevents';
import LeftPanel from 'components/Audit/LeftPanel';

const Audit: React.FC = () => {
  const { status } = useUser()
  const initialConnection = async () => {
    await preConnectAsterisk(status);
    await preConectTabbingStatus();

    await verifyCallWithoutTabbing();
  }

  useEffect(() => {
    ipcWindowShow();
    initialConnection();
    ipcIsLogged();
  }, []);

  return (
    <Wrapper>
      <CoverImage src={calculateActualCoverImage()} />
      <LeftPanel />
      <RightPanel />
      <Modal />
    </Wrapper>
  );
}

export default Audit;
