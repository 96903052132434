import { CallStore } from "store/call";
import { setInCall } from "store/call/actions";
import { setUserStatus } from 'store/user/actions';
import { StatusConstants } from "utils/constants";
import { setApproached } from 'store/call/actions';
import { approach } from "services/attendant";
import sweetAlert from "utils/sweetAlert";
import { setActualCall } from "store/socket/actions,";

export const hangup = () => {
    try {
      console.log("Ligação desligada pelo atendente");
      CallStore.getRawState().callSession.bye();
      setInCall(false);
      setUserStatus(StatusConstants.TABBING);
    } catch (e) {
      console.log("Ligação desligada pelo atendente");
      setUserStatus(StatusConstants.TABBING);
      setInCall(false);
      window.location.reload();
    }
}

export const approachCall = async () => {
  try {
    if (CallStore.getRawState().approached) {
      return;
    }
    const approachedCall = await approach();
    setActualCall(approachedCall);
    setApproached(true);
    setUserStatus(StatusConstants.APPROACHED);
  } catch {
    // sweetAlert.error("Ocorreu um erro ao abordar. Contate o suporte!");
  }
}
