import styled from "styled-components";

export const Wrapper = styled.div`

  & h1 {
    font-weight: 600;
    font-size: 18px
  }

  & h3 {
    font-weight: 600;
    margin-top: 16px;
  } 
`;

export const CenterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

export const TimeInputContainer = styled.div`
  display: flex;
  align-items: center;

  & p {
    font-weight: 600;
    margin: 0 4px;
  }

  & input {
    width: 40px;
    height: 30px;
    font-size: 14px;
    text-align: center;
    border-radius: 10px;
    border: 1px solid #6A6A6A;
  }
`;

export const ConfirmButton = styled.button`
  cursor: pointer;
  width: 100%;
  color: #1F0533;
  background: #00FFE6;
  padding: 16px 0;
  margin-top: 16px;
  border: 0;
  border-radius: 10px;
  font-weight: 600;
  font-size: 16px;

  &:hover {
    background: #00C7B3;
  }
`;

export const ErrorMessage = styled.p`
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
`; 

