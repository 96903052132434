import { useSocket } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  GetCampaignFields,
  SaveCampaignFields,
  saveDataLeadInCall
} from 'services/attendant';

import { AddContainer, InputContainer, InformationContainer } from './styles';

const LeadsFields: React.FC = () => {
  const { actualCall } = useSocket();

  const dispatch = useDispatch();
  const editField = useSelector((state: any) => state.stateFields.editField );
  const fieldValue = useSelector((state: any) => state.stateFields.fieldValue);

  const [fields, setFields] = useState<Array<{ name: string }> | null>(null);
  const [leadData, setLeadData] = useState<any>(null);

  const lead = actualCall?.lead;
  const clientId = actualCall?.client?._id;
  const campaignId = actualCall?.lead?.campaignId;

  useEffect(() => {
    if (clientId && campaignId) {
      GetCampaignFields(clientId, campaignId).then(setFields);
    }
  }, [clientId, campaignId]);

  useEffect(() => {
    setLeadData(lead?.data);
  }, [lead]);

  const handleEdit = (field: string) => {
    dispatch({ type: 'SET_EDIT_FIELD', editField: field });
    dispatch({ type: 'SET_FIELD_VALUE', fieldValue: lead?.data?.[field] || '' });
  };

  const handleSave = async (field: string) => {
    await Promise.all([
      SaveCampaignFields(lead?._id, campaignId, field, fieldValue),
      saveDataLeadInCall(actualCall?._id, lead?._id, campaignId, field, fieldValue)
    ]);

    setLeadData({ ...leadData, [field]: fieldValue });
    dispatch({ type: 'SET_EDIT_FIELD', editField: null });
  };

  return (
    <AddContainer>
      {
        Object.keys(lead?.data || []).map((field, index) => {
          if( field !== 'dynamic_fields') {

            const match = fields?.find(f =>
                f.name.toLowerCase() === field.toLowerCase()
            );

            return match ? (
              <InformationContainer key={field}>
                {field}: {editField === field ? (
                  <InputContainer>
                    <input
                      type="text"
                      value={fieldValue}
                      onChange={(event:React.ChangeEvent<HTMLInputElement>) => dispatch({ type: 'SET_FIELD_VALUE', fieldValue: event.target.value })}
                    />
                  </InputContainer>
                ) : leadData[field]}

                {editField === field ? (
                  <button onClick={() => handleSave(field)}>Salvar</button>
                ) : (
                  <button onClick={() => handleEdit(field)}>Editar</button>
                )}
              </InformationContainer>

            ) : (
              <InformationContainer key={field}>
                {field}: {lead?.data[field]}
              </InformationContainer>
            );
          }
        })
      }
    </AddContainer>
  );
}

export default LeadsFields;
