import { Store } from "pullstate";

interface IProps {
  children: React.ReactNode;
  opened: boolean;
  width?: string;
  closeModalFunction?: Function;
}

export const ModalStore = new Store<IProps>({
  children: undefined,
  opened: false,
  width: '50%',
  closeModalFunction: undefined,
});
