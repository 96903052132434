import React, { useEffect, useState } from 'react';
import { Wrapper, InputWrapper } from './styles';
import { useSocket } from 'hooks';
import { getFields } from './helper';
import IDynamicFields from 'interfaces/IDynamicFields';
import IDataFields from 'interfaces/IDataFields';
import { getDynamicFieldsData } from 'services/attendant';

const DynamicFields: React.FC = () => {
  const { actualCall } = useSocket();
  const [fields, setFields] = useState<IDynamicFields[]>([]);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<IDataFields[]>([]);
  const [dataFields, setDataFields] = useState<IDataFields[]>([]);

  useEffect(() => {
    getFields(actualCall?.client?._id).then(setFields);
    setLoading(false);
  }, [actualCall]);

  useEffect(() => {
    const getDataFields = async () => {
      const dataFieldsResponse = await getDynamicFieldsData(
        actualCall?.client?._id,
        actualCall?.lead?._id
      );

      dataFieldsResponse?.data?.map((item: any) => {
        handleChange(item?.name, item?.value, item?.type, dataFieldsResponse?.status)
      });

      setDataFields(dataFieldsResponse.data);
    }

    getDataFields();
  }, [actualCall]);

  useEffect(() => {
    const storedState = JSON.parse(sessionStorage.getItem('dynamicFields') || '[]');
    setState(storedState);
  }, []);

  const handleChange = (
    name: string,
    value: any,
    type: string,
    status: string,
  ) => {
    setState(() => {
      const index = state.map(e => e.name).indexOf(name);
      const newState = state;

      const data = {
        name: name,
        value: value,
        type: type,
      }

      if (index < 0) {
        newState.push(data)
      } else {
        newState[index] = data;
      }

      sessionStorage.setItem('dynamicFields', JSON.stringify(newState));
      sessionStorage.setItem('statusFields', JSON.stringify(status));

      return newState;
    });
  };

  const findValue = (fieldName: any) => {
    const index = state?.map(e => e.name).indexOf(fieldName);

    return state[index]?.value || '';
  }

  return (
    <Wrapper>
      {loading ? (
          <div>Carregando...</div>
        ) : (
          <React.Fragment>
            {fields.map((field: IDynamicFields) => {
              let defaultValueField = '';

              let filterValue = dataFields?.find(dataField => dataField.name === field.name);

              if(filterValue) defaultValueField = filterValue.value;

              if(defaultValueField === '') {
                defaultValueField = findValue(field.name);
              }

              return (
                <InputWrapper key={field.name}>
                  <label htmlFor={field.name}>{field.name}</label>
                  <input
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    defaultValue={defaultValueField}
                    onChange={(e) => handleChange(e.target.name, e.target.value, field.type, field.status)}
                  />
                </InputWrapper>
              );
            })}
          </React.Fragment>
        )}
    </Wrapper>
  );
};

export default DynamicFields;

