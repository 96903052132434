export enum StatusConstants {
  CONNECTING = "Connecting",
  INACTIVE = "Inactive",
  ACTIVE = "Ativo",
  WAITING = "Waiting",
  PAUSED = "Paused",
  UNPAUSE = "Unpause",
  ATTENDANCE = "Attendance",
  APPROACHED = "Aproached",
  TABBING = "Tabbing",
  ERROR = "Error"
}

export enum DayTimeConstants {
  MORNING,
  AFTERNOON,
  NIGHT,
}

export const auditOptions: {
  label: string;
  type: string;
  status: string;
}[] = [
  {
    type: 'Pendente',
    label: 'Pendente',
    status: 'Pendente'
  },
  {
    type: 'Sem Contato',
    label: 'Sem Contato',
    status: 'Sem Contato'
  },
  {
    type: 'Recusa',
    label: 'Recusa',
    status: 'Recusa'
  },
  {
    type: 'Fora do Prazo',
    label: 'Fora do Prazo',
    status: 'Fora do Prazo'
  },
  {
    type: 'Inválida',
    label: 'Inválida',
    status: 'Inválida'
  },
]

export const auditOptionsTabbing: {
  label: string;
  type: string;
  status: string;
}[] = [
  {
    type: 'Pendente',
    label: 'Pendente',
    status: 'Pendente'
  },
  {
    type: 'Sem Contato',
    label: 'Sem Contato',
    status: 'Sem Contato'
  },
  {
    type: 'Recusa',
    label: 'Recusa',
    status: 'Recusa'
  },
  {
    type: 'Fora do Prazo',
    label: 'Fora do Prazo',
    status: 'Fora do Prazo'
  },
  {
    type: 'Inválida',
    label: 'Inválida',
    status: 'Inválida'
  },
  {
    type: 'Concluída',
    label: 'Concluída',
    status: 'Concluída'
  },
]
