import { ICall } from "interfaces/ICall";
import ISipCallData from "interfaces/ISipCallData";
import { UserAgent } from "sip.js";
import { CallStore } from ".";

export const setConnected = (value: boolean) =>
  CallStore.update(s => {
    s.connected = value;
  });

export const setInCallData = (value: any) =>
  CallStore.update(s => {
    s.inCallData = value;
  });

export const setSipJsUserAgent = (value: UserAgent | undefined) =>
  CallStore.update(s => {
    s.sipJsUa = value;
  });

export const setInCall = (value: boolean) =>
  CallStore.update(s => {
    s.inCall = value;
  });

export const setCallSession = (value: any) =>
  CallStore.update(s => {
    s.callSession = value;
  });

export const setMicMuted = (value: any) =>
  CallStore.update(s => {
    s.micMuted = value;
  });

export const setRamal = (value: string) =>
  CallStore.update(s => {
    s.ramal = value;
  });

export const setInPause = (value: boolean) =>
  CallStore.update(s => {
    s.inPause = value;
  });

export const setApproached = (value: boolean) =>
  CallStore.update(s => {
    s.approached = value;
  });

export const setSipCallData = (value: ISipCallData | undefined) =>
  CallStore.update(s => {
    s.sipCallData = value;
  });

export const setCallType = (value: 'FILA' | 'REDISCAGEM') =>
  CallStore.update(s => {
    s.type = value;
  });

export const setCallCreated = (value: ICall | undefined | { _id: string }) =>
  CallStore.update(s => {
    s.callCreated = value;
  });

export const setActualAudit = (value: any) =>
  CallStore.update(s => {
    s.actualAudit = value;
  });