import { UserStore } from ".";
import { ICall } from "interfaces/ICall";
import IStatusUser from "interfaces/IStatusUser";

export const setUserStatus = (value: string) =>
  UserStore.update(s => {
    s.status = value;
  });

export const setLastCalls = (value: ICall[]) =>
  UserStore.update(s => {
    s.lastCalls = value;
  });


export const setPauseStatus = (value: string[]) =>
  UserStore.update(s => {
    s.pauseStatus = value;
  });

export const setShowingNotes = (value: boolean) =>
  UserStore.update(s => {
    s.showingNotes = value;
  });

export const setNotes = (value: string) =>
  UserStore.update(s => {
    s.notes = value;
  });

export const setIsOnPauseQueue = (isOnPauseQueue: boolean, pauseReason: string) =>
  UserStore.update(s => {
    s.pauseQueue = {
      isOnPauseQueue,
      pauseReason
    };
  });

export const setPrePause = (value: boolean) =>
  UserStore.update(s => {
    s.prePause = value;
  });

export const setLastStatus = (value: IStatusUser[]) =>
  UserStore.update(userStore => {
    userStore.lastStatus = value;
  });

export const setMicStream = (value: MediaStream) =>
  UserStore.update(userStore => {
    userStore.micStream = value;
  });

export const settPreTabbingStatus = (value: string[]) =>
  UserStore.update(userStore => {
    userStore.preTabbingStatus = value;
  });

export const setIsLogged = (value: boolean) =>
  UserStore.update(userStore => {
    userStore.isLogged = value;
  });
