import React, { useEffect, useState } from 'react';
import { Wrapper, InputWrapper } from './styles';
import { useSocket } from 'hooks';
import { getFields } from './helper';
import IDynamicFields from 'interfaces/IDynamicFields';
import IDataFields from 'interfaces/IDataFields';

const DynamicFields: React.FC = () => {
  const { actualCall } = useSocket();
  const [fields, setFields] = useState<IDynamicFields[]>([]);
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState<IDataFields[]>([]);

  useEffect(() => {
    const fetchFields = async () => {
      const fields = await getFields(actualCall?.client?._id);
      setFields(fields);
      setLoading(false);
    }

    fetchFields();
  }, [actualCall]);

  useEffect(() => {
    const storedState = JSON.parse(sessionStorage.getItem('dynamicFields') || '[]');
    setState(storedState);
  }, []);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
    status: string,
    ) => {
    const { name, value } = event.target;

    setState(() => {
      const index = state.map(e => e.name).indexOf(name);
      const newState = state;
      const data = {
        name: name,
        value: value,
        type: type,
        status: status,
      }

      if (index < 0) {
        newState.push(data)
      } else {
        newState[index] = data;
      }

      sessionStorage.setItem('dynamicFields', JSON.stringify(newState));
      return newState;
    });
  };

  const findValue = (fieldName: any) => {
    const index = state?.map(e => e.name).indexOf(fieldName);

    return state[index]?.value || '';
  }

  return (
    <Wrapper>
      {loading ? (
          <div>Carregando...</div>
        ) : (
          <React.Fragment>
            {fields.map((field: IDynamicFields) => {
              return (
                <InputWrapper key={field.name}>
                  <label htmlFor={field.name}>{field.name}</label>
                  <input
                    type={field.type}
                    id={field.name}
                    name={field.name}
                    defaultValue={findValue(field.name)}
                    onChange={(e) => handleChange(e, field.type, field.status)}
                  />
                </InputWrapper>
              );
            })}
          </React.Fragment>
        )}
    </Wrapper>
  );
};

export default DynamicFields;
