import styled from "styled-components";
import {ReactComponent as IcoMessage} from "assets/icons/ico-message-right.svg";
import {ReactComponent as IcoRedial} from "assets/icons/ico-redial.svg";
import {ReactComponent as IcoCalendar} from "assets/icons/ico-watch-calendar.svg";
import {ReactComponent as IcoHangUp} from "assets/icons/ico-hang-up-phone.svg";

export const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  width: 75%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div<{callExist?: boolean}>`
  width: auto;
  display: flex;
  justify-content: ${prop => prop.callExist ? 'space-between' : 'center'};
  background: rgba(0, 0, 0, 0.5);
  border-radius: 25px 25px 0px 0px;
  padding: 24px 24px 0 24px;
  background: #00FFE6;
`;

export const MenuCell = styled.div<{ active?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 16px;
  padding-bottom: 16px;
  width: 20%;
  cursor: pointer;

  & p {
    margin-top: 8px;
    font-size: 12px;
    color: #1F0533;
    font-weight: ${props => props.active ? 600 : 400};
  }
`;

export const ActiveBorder = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 4px;
  border-radius: 60px;
  background: #1F0533;
`;

export const MessageIcon = styled(IcoMessage)``;

export const RedialIco = styled(IcoRedial)``;

export const HangUpIco = styled(IcoHangUp)``;

export const CalendarIco = styled(IcoCalendar)``;
