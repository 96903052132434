export const ipcWindowShow = () => {
  console.log(window.ipcRenderer);
  if (!!window.ipcRenderer) {
    window?.ipcRenderer?.windowShow();
  }
}

export const ipcIsLogged = () => {
  if (!!window.ipcRenderer) {
    if (!!window?.ipcRenderer?.attendantLogged) window?.ipcRenderer?.attendantLogged();
  }
}
export const ipcIsLoggedOut = () => {
  if (!!window.ipcRenderer) {
    if (!!window?.ipcRenderer?.attendantLoggedOut) window?.ipcRenderer?.attendantLoggedOut();
  }
}