import { useCallHandler } from 'hooks';
import React from 'react';

import { approachCall, hangup } from './helper';
import {
  ActiveBorder,
  Container,
  MessageIcon,
  MenuCell,
  Wrapper,
  HangUpIco
} from './styles';

const AttendanceBottomMenu: React.FC = () => {
  const { type: callType, callCreated } = useCallHandler();

  const approachMenuCell = () => {
    return (
      <MenuCell active onClick={() => approachCall()}>
        <MessageIcon />
        <p>Abordar</p>
        <ActiveBorder />
      </MenuCell>
    );
  };

  const shouldShowApproachMenuCell = () => {
    if (callType === "FILA") {
      return !!callCreated;
    } else {
      return true;
    }
  };

  return (
    <Wrapper>
      <Container callExist={shouldShowApproachMenuCell()}>
        {shouldShowApproachMenuCell() ? approachMenuCell() : null}
        <MenuCell onClick={() => hangup()}>
          <HangUpIco />
          <p>Desligar</p>
        </MenuCell>
      </Container>
    </Wrapper>
  );
};

export default AttendanceBottomMenu;
