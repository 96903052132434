import styled from "styled-components";

export const Wrapper = styled.div`
  & h1 {
    font-weight: 600;
    text-align: center;
    font-size: 24px;
    margin-bottom: 8px;
  }

  & h4 {
    text-align: center;
    margin-bottom: 24px;
    opacity: 0.5;
    font-size: 14px;
  }

  & p {
    font-size: 12px;
    opacity: 0.5;
    margin-bottom: 4px;  
  }

  & table {
    max-height: 500px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
`;

export const CloseButton = styled.button`
  float: right;
  font-size: 14px;
  background: none;
  border: none;
  background: #f2f2f2;
  border: 1px solid #f2f2f2;
  color: black;
  padding: 6px 16px;
  margin-top: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.25s;

  &:hover {
    border: 1px solid #bfbfbf;
  }
`;