import React from 'react';
import ISchedules from 'interfaces/ISchedules';
import { CloseButton, Wrapper } from './styles';
import ScheduleItem from './ScheduleItem';
import { closeActualModal, closeSchedulesModal } from 'utils/popups';

interface IProps {
  schedules: ISchedules[];
}

const SchedulesModal: React.FC<IProps> = ({ schedules }: IProps) => {
  return (
    <Wrapper>
      <h1>Agendamentos</h1>
      <h4>Aqui estão os horários que você agendou</h4>
      <table>
        <tbody>
          {schedules.map((schedule) => {
              return (
                <React.Fragment key={schedule._id}>
                  <ScheduleItem schedule={schedule}/>
                  <tr>
                    <td>
                      {!!schedule.note ? <p>&#8618; {schedule.note}</p> : <p>&#8618; Sem observações</p>}
                    </td>
                  </tr>
                </React.Fragment>
              )
            })
          }
        </tbody>
      </table>
      <CloseButton onClick={() => closeSchedulesModal(false)}>Fechar</CloseButton>
    </Wrapper>
  );
}

export default SchedulesModal;